import { render, staticRenderFns } from "./Column.vue?vue&type=template&id=417bbd67&scoped=true&"
import script from "./Column.vue?vue&type=script&lang=js&"
export * from "./Column.vue?vue&type=script&lang=js&"
import style0 from "./Column.vue?vue&type=style&index=0&id=417bbd67&lang=scss&scoped=true&"
import style1 from "./Column.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417bbd67",
  null
  
)

export default component.exports