<template>
  <div class="Column" v-loading="searchLoading && !pageLoading">
    <Block404 v-if="show404" />
    <div v-else class="content_div">
      <div class="menu only_pc">
        <template v-if="menu && menu.length>0">
          <template v-for="(item, index) in menu">
            <div
              :key="index"
              @click="goMenu(item)"
              :class="'menu_item'+(id==item.label?' current':'')"
            >{{item.name}}</div>
            <div v-if="item.children && item.children.length>0" :key="'sub'+index" class="sub">
              <div
                v-for="(child, childIndex) in item.children"
                :key="childIndex"
                @click="goMenu(child)"
                :class="'menu_item'+(id==child.label?' current':'')"
              >{{child.name}}</div>
            </div>
          </template>
        </template>
      </div>
      <div class="main">
        <div v-if="banner && banner.length>0" class="banner">
          <el-carousel
            height="100%"
            ref="banner"
            indicator-position="none"
            arrow="never"
            @change="changeBanner"
            :initial-index="bannerIndex"
          >
            <el-carousel-item v-for="(item, index) in banner" :key="index">
              <div
                class="banner_item"
                :style="'background-image: url('+item.coverUrl+');'"
                @click="goBanner(item)"
              ></div>
            </el-carousel-item>
          </el-carousel>
          <div class="indicator">
            <div
              v-for="(item, index) in banner"
              :key="index"
              :class="'indicator_item'+(bannerIndex==index?' current':'')"
              @click="goBannerIndex(index)"
            >{{index+1}}</div>
          </div>
        </div>
        <div v-if="recommand.length>0" class="recommands">
          <div v-for="(item, index) in recommand" :key="index" class="item" @click="goInfo(item)">
            <div class="pic" :style="'background-image: url('+item.coverUrl+');'"></div>
            <div class="right">
              <div class="name">{{item.title}}</div>
              <div
                class="time"
              >{{(item.editor && item.editor.length>0)?item.editor+' | ':''}}{{item.releaseTime}}</div>
            </div>
          </div>
        </div>
        <div class="title">{{data.name}}</div>
        <template v-if="datas.length>0">
          <div class="list">
            <div
              v-for="(item, index) in datas"
              :key="index"
              :class="'item'+((item.coverUrl && item.coverUrl.length>0)?' has_pic':'')"
              @click="goInfo(item)"
            >
              <div class="left">
                <div class="name">{{item.title}}</div>
                <div class="time">
                  <span v-if="item.editor && item.editor.length>0">{{item.editor}}</span>
                  <span>{{item.releaseTime}}</span>
                </div>
              </div>
              <div
                v-if="item.coverUrl && item.coverUrl.length>0"
                class="pic"
                :style="'background-image: url('+item.coverUrl+');'"
              ></div>
            </div>
          </div>
          <div class="pagination">
            <div class="left">
              共{{totalPage}}页，当前第
              <span>{{pageNum}}</span>页
            </div>
            <el-pagination
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              :total="total"
            ></el-pagination>
          </div>
        </template>
        <div v-else class="empty">
          <Empty />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getColumnInfo, getColumnNews } from "@/axios/column";
import { getRecommendList } from "@/axios/home";
import { getHeaderMenu } from "@/axios/header";
import Block404 from "@/components/Block404.vue";
import Empty from "@/components/Empty.vue";

export default {
  name: "Column",
  components: {
    Block404,
    Empty
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      id: this.$route.params.id,
      show404: false,
      pageLoading: true,
      data: {},
      menu: [],
      banner: [],
      bannerIndex: 0,
      datas: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      totalPage: 0,
      searchLoading: false,
      recommand: [],
      metaName: "",
      metaDesc: "",
      metaKeywords: "",
      metaType: ""
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "ColumnName",
          content: this.metaName
        },
        {
          name: "ColumnDescription",
          content: this.metaDesc
        },
        {
          name: "ColumnKeywords",
          content: this.metaKeywords
        },
        {
          name: "ColumnType",
          content: this.metaType
        }
      ]
    };
  },
  watch: {
    $route(to) {
      if (to.params.id && to.params.id != this.id) {
        this.id = to.params.id;
        this.show404 = false;
        this.bannerIndex = 0;
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      document.documentElement.scrollTop = 0;
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0)"
      });
      this.pageLoading = true;
      this.data = {};
      this.datas = [];
      this.menu = [];
      this.banner = [];
      this.recommand = [];
      let query = "label=" + this.id;
      getColumnInfo(query)
        .then(res => {
          if (res.status == 1) {
            this.data = res.data;
            this.pageNum = 1;
            this.loadDatas();
            this.loadMenu();
            this.loadBanner();
            this.loadRecommand();
            this.metaName = res.data.name || "";
            this.metaDesc = res.data.description || "";
            this.metaKeywords = res.data.keywords || "";
            this.metaType = res.data.columnType || "";
          } else {
            this.show404 = true;
          }
        })
        .catch(err => {
          this.show404 = true;
        })
        .then(() => {
          loading.close();
          this.pageLoading = false;
        });
    },
    changePage(num) {
      this.pageNum = num;
      document.documentElement.scrollTop = 0;
      this.loadDatas();
    },
    loadDatas() {
      this.searchLoading = true;
      let query =
        "pageNum=" +
        this.pageNum +
        "&pageSize=" +
        this.pageSize +
        "&columnLabel=" +
        this.id;
      getColumnNews(query)
        .then(res => {
          if (res.status == 1) {
            let data = res.data;
            this.datas = data.list;
            this.total = data.total;
            this.totalPage = Math.ceil(this.total / this.pageSize);
            if (this.datas.length == 0 && this.pageNum > 1) {
              this.pageNum = 1;
              this.loadDatas();
            }
          }
        })
        .catch(err => {})
        .then(() => {
          this.searchLoading = false;
        });
    },
    loadMenu() {
      let id = this.id,
        query = "label=";
      if (
        this.data.parentId &&
        this.data.parentId != "0" &&
        this.data.parentId.length > 0 &&
        this.data.parentLabel &&
        this.data.parentLabel.length > 0
      )
        id = this.data.parentLabel;
      query += id;
      getHeaderMenu(query).then(res => {
        if (
          res.status == 1 &&
          res.data &&
          res.data[0] &&
          res.data[0].children &&
          res.data[0].children.length > 0
        ) {
          this.menu = res.data[0].children;
        }
      });
    },
    goMenu(item) {
      if (item.path)
        this.$router.push({
          path: item.path
        });
    },
    loadBanner() {
      let query = "pageNum=1&pageSize=4&recommendLabel=" + this.id;
      getRecommendList(query).then(res => {
        if (res.status == 1) {
          this.banner = res.data.list;
        }
      });
    },
    goBanner(item) {
      if (item.link)
        this.$router.push({
          path: item.link
        });
    },
    changeBanner(i) {
      this.bannerIndex = i;
    },
    goBannerIndex(i) {
      this.$refs.banner.setActiveItem(i);
    },
    loadRecommand() {
      let query = "pageNum=1&pageSize=2&isTop=1&columnLabel=" + this.id;
      getColumnNews(query).then(res => {
        if (res.status == 1) {
          this.recommand = res.data.list;
        }
      });
    },
    goInfo(item) {
      window.open("/news/" + item.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.Column {
  background: #fff;
  @include content_block;
  .content_div {
    padding-top: 40px;
    padding-bottom: 60px;
    @include body_block;
    @include flex_between_start;
    @media (max-width: $mob-screen) {
      padding-top: 16px;
      padding-bottom: 16px;
      display: block;
    }
    .menu {
      width: 240px;
      @media (max-width: 980px) {
        width: 180px;
      }
      @media (max-width: $mob-screen) {
        width: 100%;
      }
      .menu_item {
        padding: 12px;
        background: #ecf0f4;
        line-height: 25px;
        font-size: 18px;
        color: #444;
        margin-top: 8px;
        cursor: pointer;
        &:first-child {
          margin-top: 0;
        }
        &.current,
        &:hover {
          background: $primary_red;
          color: #fff;
          font-weight: 600;
        }
      }
      .sub {
        margin-top: 8px;
        .menu_item {
          background: #d5d5d5;
          padding: 12px 24px;
          &.current,
          &:hover {
            background: $primary_red;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
    .main {
      width: calc(100% - 280px);
      @media (max-width: 980px) {
        width: calc(100% - 220px);
      }
      @media (max-width: $mob-screen) {
        width: 100%;
      }
      .banner {
        height: 398px;
        position: relative;
        margin-bottom: 32px;
        border-radius: 6px;
        overflow: hidden;
        @media (max-width: 860px) {
          height: 300px;
        }
        @media (max-width: $mob-screen) {
          height: 238px;
          margin-bottom: 16px;
        }
        .el-carousel {
          height: 100%;
          /deep/ .banner_item {
            height: 100%;
            cursor: pointer;
            @include pic_bg_cover;
          }
        }
        .indicator {
          position: absolute;
          z-index: 90;
        }
        .indicator {
          left: 50%;
          transform: translateX(-50%);
          bottom: 22px;
          pointer-events: none;
          @include flex_center_center;
          @media (max-width: $mob-screen) {
            bottom: 9px;
          }
          .indicator_item {
            width: 32px;
            height: 32px;
            margin: 0 5px;
            line-height: 22px;
            font-size: 16px;
            background: rgba(0, 0, 0, 0.4);
            cursor: pointer;
            color: #fff;
            pointer-events: auto;
            @include flex_center_center;
            @media (max-width: $mob-screen) {
              width: 3px;
              height: 3px;
              margin: 0 2px;
              text-indent: -9000px;
              border-radius: 2px;
            }
            &.current {
              background: $primary_blue;
              @media (max-width: $mob-screen) {
                width: 11px;
                background: #fff;
              }
            }
          }
        }
      }
      .recommands {
        margin-bottom: 20px;
        @include flex_start_start;
        flex-wrap: wrap;
        @media (max-width: 800px) {
          display: block;
        }
        @media (max-width: $mob-screen) {
          width: 100%;
          margin-bottom: 16px;
        }
        .item {
          width: calc(50% - 20px);
          margin-left: 40px;
          margin-bottom: 40px;
          cursor: pointer;
          @include flex_between_start;
          @media (max-width: 800px) {
            width: 100%;
            margin-left: 0;
          }
          @media (max-width: $mob-screen) {
            margin-bottom: 16px;
          }
          &:nth-child(2n + 1) {
            margin-left: 0;
          }
          .pic {
            width: 43.52%;
            height: 132px;
            border-radius: 8px;
            @include pic_bg_cover;
            @media (max-width: 980px) {
              height: 120px;
            }
            @media (max-width: $mob-screen) {
              width: 120px;
              height: 80px;
            }
          }
          .right {
            width: 52.78%;
            @media (max-width: $mob-screen) {
              width: calc(100% - 136px);
            }
            .name {
              height: 70px;
              line-height: 35px;
              font-size: 24px;
              font-weight: 500;
              color: #242424;
              font-family: SourceHanSerifCN-Medium;
              @include mutiple_lines_ellipsis(2);
              @media (max-width: $mob-screen) {
                height: 40px;
                line-height: 20px;
                font-size: 14px;
              }
            }
            .time {
              margin-top: 43px;
              line-height: 22px;
              font-size: 16px;
              color: #888;
              @include single_line_ellipsis;
              @media (max-width: $mob-screen) {
                margin-top: 8px;
                line-height: 16px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .title {
        position: relative;
        line-height: 33px;
        font-size: 24px;
        color: $primary_blue;
        font-weight: 600;
        padding-left: 14px;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:before {
          display: block;
          content: "";
          width: 6px;
          height: 23px;
          background: $primary_blue;
          position: absolute;
          left: 0;
          top: 5px;
        }
        @media (max-width: $mob-screen) {
          line-height: 22px;
          font-size: 16px;
          padding-bottom: 8px;
          &:before {
            height: 12px;
          }
        }
      }
      .list {
        margin-bottom: 48px;
        @media (max-width: $mob-screen) {
          margin-bottom: 24px;
        }
        .item {
          padding: 24px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          min-height: 132px;
          cursor: pointer;
          @media (max-width: $mob-screen) {
            padding: 12px 0;
            min-height: auto;
          }
          .left {
            .name {
              line-height: 40px;
              font-size: 28px;
              color: #242424;
              font-weight: 500;
              font-family: SourceHanSerifCN-Medium;
              @media (max-width: $mob-screen) {
                line-height: 20px;
                font-size: 16px;
              }
            }
            .time {
              margin-top: 26px;
              line-height: 28px;
              font-size: 20px;
              color: #888;
              @media (max-width: $mob-screen) {
                margin-top: 10px;
                line-height: 20px;
                font-size: 14px;
              }
              span {
                padding-left: 40px;
                &:first-child {
                  padding-left: 0;
                }
                @media (max-width: $mob-screen) {
                  padding-left: 20px;
                }
              }
            }
          }
          &.has_pic {
            @include flex_between_start;
            .left {
              width: calc(100% - 264px);
              @media (max-width: 1000px) {
                width: calc(100% - 220px);
              }
              @media (max-width: 700px) {
                width: calc(100% - 180px);
              }
              @media (max-width: $mob-screen) {
                width: calc(100% - 110px);
              }
            }
            .pic {
              width: 232px;
              height: 132px;
              border-radius: 8px;
              @include pic_bg_cover;
              @media (max-width: 1000px) {
                width: 200px;
              }
              @media (max-width: 700px) {
                width: 160px;
              }
              @media (max-width: $mob-screen) {
                width: 100px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Column {
      background: nth($bg_color, $i);
      .content_div {
        .menu {
          a {
            background: nth($bg_color, $i);
            color: nth($text_color, $i);
            &.current {
              background: nth($bg_color, $i);
              color: nth($text_color, $i);
              border: 1px solid nth($text_color, $i);
            }
          }
        }
        .main {
          .recommands {
            .item {
              .right {
                .name {
                  color: nth($text_color, $i);
                }
                .time {
                  color: nth($text_color, $i);
                }
              }
            }
          }
          .title {
            color: nth($text_color, $i);
            border-bottom: 1px solid nth($text_color, $i);
          }
          .list {
            .item {
              border: 1px solid nth($text_color, $i);
              .name {
                color: nth($text_color, $i);
              }
              .time {
                color: nth($text_color, $i);
              }
            }
          }
        }
      }
    }
  }
}
</style>